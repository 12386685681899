import { WOW } from 'wowjs';
import mixitup from 'mixitup/dist/mixitup';

(function($) {
    "use strict"; // Start of use strict

    // Load WOW.js on non-touch devices
    var isPhoneDevice = "ontouchstart" in document.documentElement
    $(document).ready(function() {
    if (isPhoneDevice) {
        //mobile
        $('body').addClass('wow-disabled')
    } else {
        //desktop
        // Initialize WOW.js
        const wow = new WOW({
        offset: 50
        })
        wow.init();
    }
    });

    // Collapse the navbar when page is scrolled
    $(window).scroll(function() {
        if ($("#mainNav").offset().top > 100) {
            $("#mainNav").addClass("navbar-shrink");
        } else {
            $("#mainNav").removeClass("navbar-shrink");
        }
    });

    // Activate scrollspy to add active class to navbar items on scroll
    // $('body').scrollspy({
    //     target: '#mainNav',
    //     offset: 68
    // });
    // Highlight the current page in #mainNav
    $('#mainNav a[href="' + window.location.pathname + '"]').addClass('active')

    // Smooth Scrolling: Smooth scrolls to an ID on the current page
    // To use this feature, add a link on your page that links to an ID, and add the .page-scroll class to the link itself. See the docs for more details.
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 68)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    // Closes responsive menu when a link is clicked
    $('.navbar-collapse>ul>li>a, .navbar-brand').click(function() {
        $('.navbar-collapse').collapse('hide');
    });

    // Activates floating label headings for the contact form
    $("body").on("input propertychange", ".floating-label-form-group", function(e) {
        $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function() {
        $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function() {
        $(this).removeClass("floating-label-form-group-with-focus");
    });

    // Magnific Popup jQuery Lightbox Gallery Settings
    $('.gallery-link').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        },
        image: {
            titleSrc: 'title'
        }
    });

    // Magnific Popup Settings
    $('.grid-item.mix').magnificPopup({
        type: 'iframe'
    });

    if ($('.portfolio-grid').length > 0) {
        $('.portfolio-grid .grid-item').each(function(index, element) {
            var id = $(element).data('id')
            if ($('body > .navbar + .tns-outer .carousel .featured-client[data-id="' + id + '"]').length > 0) {
                return
            }
            $(element).addClass('default').find('.mix').addClass('default')
        })
    }

    // Mixitup Settings
    $('.grid').each(function(index, element) {
        var mixer = mixitup(element, {
            load: {
                filter: '.default'
            }
        })
    })

})(jQuery); // End of use strict
